<template>
  <ul role="navigation" class="dossier-top-list">
    <li role="menuitem" class="dossier-top-list__item">
      <!-- Prevent nuxt-route for now, this site uses proxy-pass legacy link -->
      <a href="/abo" class="link-button"> ABO </a>
      <!-- <nuxt-link :to="{name: 'abo'}" class="link-button">
        ABO
      </nuxt-link> -->
    </li>
    <li v-for="(navItem, index) in dossierNavigation" :key="index" role="menuitem" class="dossier-top-list__item">
      <ArticleLink
        v-if="navItem.attributes.link.type === 'ContentLink' && navItem.attributes.link.relationships.article"
        :article="navItem.attributes.link.relationships.article"
      >
        {{ navItem.attributes.link.attributes.text }}
      </ArticleLink>
      <NavigationLink
        v-else-if="navItem.attributes.link.type === 'ChannelLink'"
        :channel="navItem.attributes.link.relationships.channel"
      >
        {{ navItem.attributes.link.attributes.text }}
      </NavigationLink>
      <a
        v-else-if="navItem.attributes.link.attributes.url"
        :href="navItem.attributes.link.attributes.url"
        :target="navItem.attributes.link.attributes.target"
      >
        {{ navItem.attributes.link.attributes.text }}
      </a>
    </li>
  </ul>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import NavigationLink from '@/components/ui/page/navigation-link'

export default {
  components: {
    NavigationLink,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      navigation: {
        isActive: false,
      },
    }
  },
  computed: {
    dossierNavigation() {
      return this.$store.getters['navigation/dossierNavigation']
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars.scss';

.dossier-top-list {
  display: none;

  @media all and (min-width: $screen-width-767) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
  }

  @media screen and (min-width: $screen-width-1023) {
  }

  &__item {
    list-style: none;
    font-size: 18px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;

    &:before {
      display: none;
    }

    @media all and (min-width: $screen-width-767) {
      padding-left: 0;
      padding-right: 25px;
    }

    @media all and (min-width: $screen-width-1023) {
      padding-right: 60px;
    }

    a {
      &:hover,
      &:active,
      &:focus {
        color: lighten($color-turquoise, 10%);
        transition-duration: 0.3s;
      }
    }

    a.link-button {
      font-size: 15px;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($color-turquoise, 10%);
        color: $color-white;
      }
    }
  }

  &.dossiers-mobile {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px;

    .dossier-top-list__item {
      padding: 20px 0 0 20px;
    }

    @media all and (min-width: $screen-width-767) {
      display: none;
    }
  }

  &.dossiers-desktop {
    display: none;

    @media all and (min-width: $screen-width-767) {
      display: flex;
    }
  }
}
</style>
